import { toast } from "react-toastify";
import { IUserProfile } from "../interfaces/user";
import { CssSizeUnit } from "../options/StyleTypes";

export function trimText(text: string, maxLength: number) {
    if (text.length > maxLength && maxLength > 3)
        return `${text.trim().substring(0, maxLength - 3).trim()}...`

    return text;
}

export function camalize(str: string) {
    return (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
        return chr.toUpperCase();
    });
}

export function titleCase(str: string, replace?: string, r_with?: string): string {
    if (replace && r_with)
        str = str.replaceAll(replace, r_with);
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

export function ConstractFullName(firstName: string, lastName: string, middleName?: string) {
    return (firstName ?? "") + " " +
        ((middleName === undefined || middleName === null || middleName === "") ? "" : "(" + middleName + ") ") +
        (lastName ?? "");
}

export function ConstractFullNameFromProfile(profile?: IUserProfile) {
    if (!profile) return " - ";

    return (profile.firstName ?? "") + " " +
        (profile.lastName ?? "");
}

function isOverflown(element: HTMLElement) {
    return element.scrollHeight > element.clientHeight
}


export function resizeText(elements: NodeListOf<HTMLElement>, minSize = 12, maxSize = 512, step = 0, unit: CssSizeUnit = 'px') {
    (elements).forEach(el => {
        let i = minSize
        let overflow = false

        const parent = el.parentElement

        while (!overflow && i < maxSize && parent) {
            el.style.fontSize = `${i}${unit}`
            overflow = isOverflown(parent)

            if (!overflow) i += step
        }

        // revert to last state where no overflow happened
        el.style.fontSize = `${i - step}${unit}`
    })
}

export const copyToClipboard = async (text:string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard");
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };