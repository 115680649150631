import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext } from 'react'
import { IHubOrderProduct, IHubShoppingOrder } from '../../../interfaces/order'
import { Button, Divider, Form, Header, Icon, Label, List, Segment, Table } from 'semantic-ui-react'
import { HubOrderProductStatus } from '../../../options/HubOrderProductStatus'
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import NumericInput from '../../../form/NumericInput'
import ErrorMessage from '../../../form/ErrorMessage'
import CameraCapture from '../../../common/CameraCapture'
import { RootStoreContext } from '../../../stores/RootStoreContext'
import { currencyFormat } from '../../products/functions/productHelper'
import OrderPreparationTableRow from '../../orderMonitoring/components/OrderPreparationTableRow'
import HubOrderReplacement from './HubOrderReplacement'
import { HubOrderReplacementStore } from '../functions/hubOrderReplacementStore'
import agent from '../../../api/agent'
import { Guid } from '../../../helpers/guid'
import { ICataloguePriceForList } from '../../../interfaces/catalogue'
import { copyToClipboard } from '../../../helpers/textHelper'

interface IProps {
    item: IHubOrderProduct;
    updatingProduct: boolean;
    onDoubleClick: (catalogueId: string) => void;
    toggleHasImage: (catalogueId: string) => Promise<void>;
    updateProduct: (productId: string, quantity: number, status: "fully_received" | "partially_received" | "missing" | "returned" | 'pending') => Promise<void>;
    updateProductAmount: (productId: string, returnedAmount: number, missingAmount: number) => Promise<void>;
    updateProductForShop: (productId: string, packedAmount: number) => Promise<void>;
    orders?: IHubShoppingOrder[];
    undoShoppingOrderProduct?: (orderId: string, productId: string, locationId: string) => Promise<void>;
    updateShoppingOrderPackedProduct?: (orderId: string, productId: string, locationId: string, quantity: number) => Promise<void>;
    refundShoppingOrderProduct?: (orderId: string, catalogueId: string, locationId: string, quantity: number) => Promise<void>;
    replaceProductForShop?: (values: any) => Promise<void>;
}

const VerifyOrderTableRow: React.FC<IProps> = ({
    item,
    updatingProduct,
    onDoubleClick,
    updateProduct,
    updateProductAmount,
    toggleHasImage,
    orders,
    undoShoppingOrderProduct,
    updateShoppingOrderPackedProduct,
    refundShoppingOrderProduct,
    updateProductForShop,
    replaceProductForShop }) => {
    const context = useContext(RootStoreContext)
    const { openModal, closeModal } = context.modalStore;
    const { locations } = context.hubOrdersStore;
    const [issueOpen, setIssueOpen] = React.useState(false);
    const [prices, setPrices] = React.useState<ICataloguePriceForList[]>([])

    const get_other_prices = async () => {
        agent.Catalogue.Admin.refreshData(item.catalogueId).then(
            (result) => setPrices(result.prices)
        )
    }

    return (
        <Segment style={{ padding: "0px", marginTop: "8px", marginBottom: "8px" }} onDoubleClick={() => onDoubleClick(item.catalogueId)}>
            <div
                className={`fxDisplay fxFixed fxAlignCenter fxJustifyBetween ${item.receivedAmount === item.quantity ? "positive-order" : ((item.receivedAmount ?? 0) > 0 && item.receivedAmount !== item.quantity) ? "warning-order" : (item.packedAmount === 0 ? "error-order" : "")}`}
                key={item.productId}
            >
                <div style={{ maxWidth: "250px", width: "250px", minWidth: "250px" }}>
                    <Header size='small'>
                        {item.discontinued && <Header.Subheader style={{ color: "red" }}>Discontinued</Header.Subheader>}
                        {item.productTitle}
                        <Header.Subheader>
                            {item.catalogueTitle}
                            <Icon style={{marginLeft:"8px"}} name="copy" onClick={() => copyToClipboard(item.catalogueTitle)} color='green' size='large' />
                            <Icon style={{marginLeft:"8px"}} name="exchange" onClick={get_other_prices} color='orange' size='large' />
                            <Icon style={{marginLeft:"8px"}} name="external alternate" onClick={() => window.open(`${window.location.origin}/compare?id=${item.associationId}`, "_blank")} color='olive' size='large' />
                        </Header.Subheader>
                        <Header.Subheader>{item.sku ?? "-"}</Header.Subheader>
                    </Header>
                    {prices.length > 0 && <List horizontal>
                        {prices.slice().sort((a, b) => a.price < b.price ? -1 : 1).map((x, i) =>
                            <List.Item key={`${Guid.newGuid()}`} style={{ textAlign: 'center', marginRight: "10px" }}>
                                <div className="fxDirCol" style={{ width: "65px" }}>
                                    <div>{x.locations.map(l => locations.filter(s => s.id === l)[0].title)}</div>
                                    <div style={{ fontSize: "16px", marginTop: "4px", fontWeight: "600" }}>{currencyFormat(x.price, true)}</div>
                                    <div style={{ fontSize: "12px", fontWeight: "100", color: (x.profit && x.profit > 0 ? "green" : "red") }}>{currencyFormat(x.profit ?? 0, true)}</div>
                                </div>
                            </List.Item>
                        )}</List>}
                </div>
                <div style={{ maxWidth: "50px", minWidth: "50px", textAlign: "right" }}>
                    <Header size='small'>
                        {currencyFormat(item.currentPrice * item.quantity)}
                        <Header.Subheader><span className='ct-green-text'>{currencyFormat(item.currentPrice)}</span>/<span style={{ color: "red" }}>{currencyFormat(item.shopPrice)}</span></Header.Subheader>
                    </Header>
                </div>
                <div style={{ maxWidth: "100px", minWidth: "100px", textAlign: "center" }}>
                    <Icon name="camera" size='big' color={item.hasImage === true ? "green" : "red"} onClick={() => openModal(<CameraCapture catalogueId={item.catalogueId} closeModal={closeModal} />, "fullscreen", undefined, false, false)} />
                    <Icon name="image" size='big' color={item.hasImage === true ? "green" : "red"} style={{ marginLeft: "10px" }} onClick={() => toggleHasImage(item.catalogueId)} />
                </div>
                <div style={{ maxWidth: "100px", minWidth: "100px", textAlign: "center" }}>
                    <Label size='large' color={HubOrderProductStatus.filter(f => f.value === (item.shopStatus ?? "pending"))[0].color}>
                        {HubOrderProductStatus.filter(f => f.value === (item.shopStatus ?? "pending"))[0].text}
                    </Label>
                    {(item.shopStatus ?? "pending") === "pending" && <Fragment>
                        <Divider />
                        <FinalForm
                            initialValues={{ 'packedAmount': undefined }}
                            onSubmit={(values: any) =>
                                updateProductForShop(item.productId, +(values.packedAmount))
                                    .catch((error) => ({
                                        [FORM_ERROR]: error,
                                    }))
                            }
                            render={({
                                handleSubmit,
                                submitting,
                                invalid,
                                dirtySinceLastSubmit,
                                submitError,
                            }) => (
                                <Form onSubmit={handleSubmit} error className="attached fluid">
                                    <Form.Field>
                                        <label style={{ fontSize: "13px", textAlign: "left", color: "Blue" }}>
                                            {"Packed Amount"}
                                        </label>
                                        <Field
                                            name="packedAmount"
                                            component={NumericInput}
                                            placeholder={"Packed Amount"}
                                        />
                                    </Form.Field>

                                    {submitError && !dirtySinceLastSubmit && (
                                        <ErrorMessage error={submitError} />
                                    )}

                                    <div style={{ marginTop: "7px", textAlign: "center" }}>
                                        <Button
                                            disabled={invalid && !dirtySinceLastSubmit}
                                            loading={submitting}
                                            color="blue"
                                            content={"Save"}
                                        />
                                    </div>
                                </Form>
                            )}
                        />
                    </Fragment>}
                </div>
                <div style={{ maxWidth: "100px", minWidth: "100px", textAlign: "center" }}>
                    <Label size='large' color={HubOrderProductStatus.filter(f => f.value === (item.status ?? "pending"))[0].color}>
                        {HubOrderProductStatus.filter(f => f.value === (item.status ?? "pending"))[0].text}
                    </Label>
                </div>
                <div style={{ maxWidth: "200px", minWidth: "200px", textAlign: "center" }}>
                    <Segment >
                        <div className='fxDisplay fxFixed fxJustifyEven' style={{ fontSize: "18px", fontWeight: "600" }}>
                            <div style={{ color: ((item.packedAmount ?? 0) < item.quantity) ? "red" : "green" }}>
                                <div>
                                    {item.packedAmount ?? 0}
                                </div>
                                <span style={{ fontWeight: "100", fontSize: "14px" }}>Packed</span>
                            </div>

                            <div>
                                <div>
                                    {item.quantity ?? 0}
                                </div>
                                <span style={{ fontWeight: "100", fontSize: "14px" }}>Request</span>
                            </div>
                        </div>
                        {((item.receivedAmount ?? 0) > 0 || (item.missingAmount ?? 0) > 0 || (item.returnedAmount ?? 0) > 0) &&
                            <div style={{ fontWeight: "bolder", fontSize: "15px" }}>
                                <Divider clearing style={{ marginTop: "3px", marginBottom: "3px" }} />
                                {(item.receivedAmount ?? 0) > 0 && <span style={{ color: "green", marginRight: "5px" }}>(Acc) : {item.receivedAmount}</span>}
                                {(item.missingAmount ?? 0) > 0 && <span style={{ color: "red" }}>(Mis) : {item.missingAmount}</span>}
                                {(item.returnedAmount ?? 0) > 0 && <span style={{ marginLeft: "5px", color: "orangered" }}>(Ret) : {item.returnedAmount}</span>}
                            </div>}
                    </Segment>
                </div>
                <div style={{ maxWidth: "110px", minWidth: "110px", textAlign: "center" }}>
                    {(!item.status || item.status === "pending") && <Fragment>
                        <Icon disabled={updatingProduct} name="check" size='large' color='green' onClick={() => updateProduct(item.productId, item.packedAmount ?? 0, item.packedAmount === item.quantity ? "fully_received" : ((item.packedAmount ?? 0) === 0 ? "missing" : "partially_received"))} />
                        <Icon disabled={updatingProduct} style={{ marginLeft: "10px", marginRight: "10px" }} name="warning sign" size='large' color='yellow' onClick={() => setIssueOpen(!issueOpen)} />
                        {replaceProductForShop && <Icon name="exchange" size='large' color='red' onClick={() => openModal(
                            <HubOrderReplacement
                                replaceFunction={replaceProductForShop}
                                replacementProdId={item.productId}
                                replacementCatId={item.catalogueId}
                                store={new HubOrderReplacementStore()}
                            />,
                            "large",
                            `${item.productTitle} x ${item.quantity} -- Unit Cost : ${item.currentPrice}`,
                            true,
                            false,
                            undefined,
                            undefined
                        )}
                        />}
                    </Fragment>}
                    {(item.status !== undefined && item.status !== "pending") && <Icon disabled={updatingProduct} name="undo alternate" size='large' color='red' onClick={() => updateProduct(item.productId, 0, "pending")} />}

                    {issueOpen && <Fragment>
                        <Divider />
                        <FinalForm
                            initialValues={{ 'returnedAmount': item.returnedAmount, 'missingAmount': item.missingAmount }}
                            onSubmit={(values: any) =>
                                updateProductAmount(item.productId, values.returnedAmount, values.missingAmount)
                                    .then((values) => setIssueOpen(false))
                                    .catch((error) => ({
                                        [FORM_ERROR]: error,
                                    }))
                            }
                            render={({
                                handleSubmit,
                                submitting,
                                invalid,
                                dirtySinceLastSubmit,
                                submitError,
                            }) => (
                                <Form onSubmit={handleSubmit} error className="attached fluid">
                                    <Form.Field>
                                        <label style={{ fontSize: "13px", textAlign: "left", color: "Blue" }}>
                                            {"Returned Amount"}
                                        </label>
                                        <Field
                                            name="returnedAmount"
                                            component={NumericInput}
                                            placeholder={"Returned Amount"}
                                        />
                                    </Form.Field>

                                    <Form.Field style={{ marginBottom: "3px" }}>
                                        <label style={{ fontSize: "13px", textAlign: "left", color: "Blue" }}>
                                            {"Missing Amount"}
                                        </label>
                                        <Field
                                            name="missingAmount"
                                            component={NumericInput}
                                            placeholder={"Missing Amount"}
                                        />
                                    </Form.Field>

                                    {submitError && !dirtySinceLastSubmit && (
                                        <ErrorMessage error={submitError} />
                                    )}

                                    <div style={{ marginTop: "7px", textAlign: "center" }}>
                                        <Button
                                            disabled={invalid && !dirtySinceLastSubmit}
                                            loading={submitting}
                                            color="blue"
                                            content={"Save"}
                                        />
                                    </div>
                                </Form>
                            )}
                        />
                    </Fragment>}
                </div>
            </div>
            {orders && <div>
                <Divider />
                <List basic="very" className='hub-order-verify-table'>
                    {orders
                        ?.slice()
                        .sort((a, b) => a.displayId > b.displayId ? 1 : -1)
                        .map((x) =>
                            <Table key={x.displayId}>
                                <Table.Body>
                                    {x.products.filter(f => f.catalogueId === item.catalogueId).map((f) =>
                                        <OrderPreparationTableRow
                                            key={f.catalogueId}
                                            orderId={x.id}
                                            displayId={x.displayId}
                                            item={f}
                                            orderClosed={false}
                                            undoProduct={(productId: string, locationId: string) => {
                                                if (undoShoppingOrderProduct)
                                                    return undoShoppingOrderProduct(x.id, productId, locationId);
                                                else return Promise.resolve()
                                            }}
                                            updatePackedProduct={(productId: string, locationId: string, quantity: number) => {
                                                if (updateShoppingOrderPackedProduct)
                                                    return updateShoppingOrderPackedProduct(x.id, productId, locationId, quantity);
                                                else return Promise.resolve()
                                            }}
                                            refundProduct={(catalogueId: string, locationId: string, quantity: number) => {
                                                if (refundShoppingOrderProduct)
                                                    return refundShoppingOrderProduct(x.id, catalogueId, locationId, quantity);
                                                else return Promise.resolve()
                                            }}
                                            updatingProduct={updatingProduct}
                                            toggleHasImage={toggleHasImage}
                                        />)}
                                </Table.Body>
                            </Table>)
                    }
                </List>
            </div>}
        </Segment>
    )
}

export default observer(VerifyOrderTableRow);